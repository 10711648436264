import { Injectable, ErrorHandler } from '@angular/core';
import { LoadingControllerHandler } from './loading-controller-handler';
import { ApplicationInsightsService } from 'src/services/applicationInsights.service';

@Injectable({ providedIn: 'root' })
export class MTAErrorHandler implements ErrorHandler {
  constructor(private loadingControllerHandler: LoadingControllerHandler,     
     private appInsightsService: ApplicationInsightsService) { }

  handleError(error: any) {
    if (this.loadingControllerHandler.loadingController) {
      this.loadingControllerHandler.dismissAllLoaders();
    }
    if (!this.loadingControllerHandler.alertController) {
      this.loadingControllerHandler.presentAlert(error.message);
    }

    console.log('error',error);
    const internalError = {
      subject: 'TO Notes Logs',
      body: error.message,
      date: new Date().toString(),
      errorStack: error.stack
    };   
    this.appInsightsService.logException(new Error(`Message: ${error.message}`),error, internalError);
  }
}
