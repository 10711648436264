import { Injectable } from '@angular/core';
import { WindowRef } from '../../shared/window-ref';
import { AppStorageProvider } from '../appStorage/app-storage';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { AlertController, Platform } from '@ionic/angular';
import { LogLevel } from 'src/data/InternalTypes';

@Injectable()
export class AnalyticsProvider {
  private logLevel: LogLevel;
  private eventTrackFailure = false;
  private appInsights: ApplicationInsights;

  constructor(
    private platform: Platform,
    private window: WindowRef,
    private alertCtrl: AlertController,
    private storage: AppStorageProvider,
  ) {
    this.platform.ready().then(async () => {
      const log = (await this.storage.GetLocal('logLevel')) as string;
      if (log) {
        this.logLevel = LogLevel[log];
      } else {
        this.storage.SetLocal('logLevel', LogLevel.Error);
        this.logLevel = LogLevel.Error; // defaults log to error
      }      
    });
  }

  private loadAppInsights() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        url: 'assets/ApplicationInsightSDK/ai.js',
        disableAjaxTracking: true
      }
    });
    this.appInsights.loadAppInsights();
  }

  setLogLevel(level: LogLevel) {
    this.logLevel = level;
  }

  getLogLevel() {
    return this.logLevel;
  }

  trace(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Trace)) {
      if (!params) params = {};
      const list: any = Object.assign(
        {
          LogLevel: LogLevel.Trace,
          message
        },
        params
      );
      this.trackEvent(event, list);
    }
  }

  info(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Info)) {
      if (!params) params = {};
      const list: any = Object.assign(
        {
          LogLevel: LogLevel.Info,
          message
        },
        params
      );
      this.trackEvent(event, list);
    }
  }

  warn(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Warn)) {
      if (!params) params = {};
      const list: any = Object.assign(
        {
          LogLevel: LogLevel.Warn,
          message
        },
        params
      );
      this.trackEvent(event, list);
    }
  }

  error(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Error)) {
      if (!params) params = {};
      const list: any = Object.assign({
        LogLevel: LogLevel.Error,
        message
      },
        params
      );
      this.trackEvent(event, list);
    }
  }

  private canLog(level: LogLevel) {
    switch (this.logLevel) {
      case LogLevel.Trace:
        return true;
      case LogLevel.Info:
        if (level !== LogLevel.Trace) {
          return true;
        }
        return false;
      case LogLevel.Warn:
        if (level !== LogLevel.Trace && level !== LogLevel.Info) {
          return true;
        }
        return false;
      case LogLevel.Error:
        if (level === LogLevel.Error) {
          return true;
        }
        return false;
    }
  }

  private trackEvent(event: string, params: any) {   
      params['Platform'] = 'windows';
      this.appInsights.trackEvent({
        name: event,
        properties: {
          Properties: this.getString(params)  // Doing this since Appcenter following this standard.
        }
      });    
  }

  private getString(params: any): string {
    const cache = [];
    return JSON.stringify(params, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Duplicate reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
  }

}

